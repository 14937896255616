import React from 'react'
import PropTypes from 'prop-types'

import { graphql } from 'gatsby'
import { Layout, Image, SEO } from '../components'

const PresentationPage = ({ data }) => (
  <Layout>
    <SEO
      title="A propos"
      description="Nous pouvons vous recevoir dans notre show-room, où vous pourrez vous
    familiariser avec les portes de garage Hörmann ainsi que les portes
    d’entrée et les RollMatic."
    />
    <h1 className="title">Qui sommes-nous ?</h1>
    <Image
      fluid={data.image.childImageSharp.fluid}
      alt="bâtiment Portes Services"
    />
    <div className="flex flex-col justify-between my-5 w-full">
      <div className="flex justify-between mb-3">
        <p className="w-1/2 mr-10">
          Depuis 1938, en deux générations, nous avons forgé notre expérience
          dans le domaine de la fermeture du bâtiment.
        </p>
        <p className="w-1/2">
          En 1999, nous sommes devenus dealer officiel Hörmann.
        </p>
      </div>
      <div className="flex justify-between mb-3">
        <p className="w-1/2 mr-10">
          Cette expérience nous a amené dans l’extrusion des profils en PVC et
          la fabrication de châssis.
        </p>
        <p className="w-1/2">
          Au vu du succès et de la demande croissante, nous avons installé notre
          propre cabine de peinture, avec laquelle, nous pouvons répondre à une
          large gamme de teintes, adaptées à vos menuiseries extérieures.
          N’hésitez pas à demander des renseignements !
        </p>
      </div>
      <div className="flex justify-between mb-3">
        <p className="w-1/2 mr-10">
          Cette collaboration nous a permis de développer le secteur, et
          d'améliorer dans le souci de la qualité et du service.
        </p>
        <p className="w-1/2">
          Nous pouvons vous recevoir dans notre show-room, où vous pourrez vous
          familiariser avec les portes de garage Hörmann ainsi que les portes
          d’entrée et les RollMatic.
        </p>
      </div>
    </div>
    <p> Notre équipe de 10 collaborateurs reste à votre disposition.</p>
  </Layout>
)

PresentationPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query {
    image: file(relativePath: { eq: "ps.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default PresentationPage
